import styled from "@emotion/styled"
import { Flex, Image } from "theme-ui"
import { useLocation } from "@reach/router"
import React, { useContext } from "react"
import { InterfaceContext } from "../../context/interface-context"
import FreeShippingBar from "../free-shipping-bar"
import { useNotificationContext } from "../../context/notification-context"
import StoreContext from "../../context/store-context"
import { handleItemsInCart } from "../../util/handlers"
import Link from "../base/link"
import HeaderLogo from "../../assets/images/header-logo.png"
import { navigate } from "gatsby"

import MenuIcon from "../../assets/icons/menu.svg"

// HEADER
const SiteHeaderDesktop = styled.div`
  display: none;

  ${({ theme }) => theme.bp.largeDesktop} {
    display: flex;
  }
`

const SiteHeaderMobile = styled.div`
  display: block;

  ${({ theme }) => theme.bp.largeDesktop} {
    display: none;
  }
`

const SiteHeader = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: ${({ theme }) => theme.height[1]}px;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  background-color: ${({ theme }) => theme.colors.background};
  border-bottom: ${({ theme }) => theme.borders.thin};
  position: fixed;
  top: 0;
  z-index: 100;

  > div,
  nav {
    display: flex;
    align-items: center;
    height: 100%;
  }

  ${({ theme }) => theme.bp.largeDesktop} {
    padding-left: 30px;
    padding-right: 30px;
  }
`

// BRANDING
const SiteHeaderBranding = styled.div`
  ${({ theme }) => theme.bp.Desktop} {
    width: 40%;
  }
`

// LOGO
const SiteHeaderLogo = styled(Link)`
  font-size: ${({ theme }) => theme.fontSizes[4]}px;

  ${({ theme }) => theme.bp.desktop} {
    font-size: ${({ theme }) => theme.fontSizes[5]}px;
  }

  ${({ theme }) => theme.bp.largeDesktop} {
    font-size: ${({ theme }) => theme.fontSizes[6]}px;
  }
`

// NOTE (free shipping, etc.)
const SiteHeaderNote = styled.p`
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 0;
  font-size: ${({ theme }) => theme.fontSizes[0]}px;

  ${({ theme }) => theme.bp.desktop} {
    font-size: ${({ theme }) => theme.fontSizes[1]}px;
    margin-left: 20px;
    margin-right: 20px;
  }

  ${({ theme }) => theme.bp.largeDesktop} {
    font-size: ${({ theme }) => theme.fontSizes[2]}px;
    margin-inline: auto;
  }
`

// NAVIGATION
const SiteHeaderNav = styled.nav`
  margin-left: 30px;
  margin-right: 30px;

  ${({ theme }) => theme.bp.largeDesktop} {
    margin-left: 50px;
  }
`

const SiteHeaderNavItem = styled.span`
  flex-shrink: 0;

  & + & {
    margin-left: 30px;
  }
`

const SiteHeaderNavLink = styled(Link)`
  font-size: ${({ theme }) => theme.fontSizes[2]}px;
  transition: color 0.2s ease-in-out;

  &:hover {
    color: ${({ theme }) => theme.colors.lightGreen};
  }

  ${({ theme }) => theme.bp.largeDesktop} {
    font-size: ${({ theme }) => theme.fontSizes[3]}px;
  }
`

const SiteHeaderNavButton = styled.button`
  color: inherit;
  font-size: ${({ theme }) => theme.fontSizes[2]}px;
  transition: color 0.2s ease-in-out;
  border: 0;
  background-color: transparent;
  padding: 0;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.lightGreen};
  }

  ${({ theme }) => theme.bp.largeDesktop} {
    font-size: ${({ theme }) => theme.fontSizes[3]}px;
  }
`

// EXTRAS (right side of header)
const SiteHeaderExtras = styled.div`
  flex-shrink: 0;
  margin-left: 30px;
`

const SiteHeaderExtrasItem = styled.div`
  flex-shrink: 0;
  font-size: ${({ theme }) => theme.fontSizes[2]}px;
  cursor: pointer;
  transition: color 0.2s ease-in-out;

  &:hover {
    color: ${({ theme }) => theme.colors.lightGreen};
  }

  span {
    display: block;
    min-width: 24px;
    height: 24px;
    padding: 2px;
    border: ${({ theme }) => theme.borders.thin};
    border-radius: 50%;
    font-size: ${({ theme }) => theme.fontSizes[2]}px;
    text-align: center;
  }

  & + & {
    margin-left: 20px;
  }

  ${({ theme }) => theme.bp.desktop} {
    & + & {
      margin-left: 25px;
    }
  }

  ${({ theme }) => theme.bp.largeDesktop} {
    font-size: ${({ theme }) => theme.fontSizes[3]}px;

    & + & {
      margin-left: 30px;
    }
  }
`

// MOBILE NAVIGATION TOGGLE
const SiteHeaderMobileNavToggle = styled(MenuIcon)`
  width: 24px;
  height: 24px;
  margin-top: 4px;
  fill: ${({ theme }) => theme.colors.darkGreen};
`

const Header = () => {
  const {
    toggleSearch,
    toggleProductNav,
    toggleRegionOpen,
    showCart,
    hideDrawers,
    hideShippingBar,
  } = useContext(InterfaceContext)

  const { cart, freeShippingLimit } = useContext(StoreContext)

  const { pathname } = useLocation()

  const { setNotificationList } = useNotificationContext()

  const hideMenuLocations = ["checkout", "payment"]

  const hideMenuItems = hideMenuLocations.some((location) => {
    return pathname.includes(location)
  })

  return (
    <>
      <SiteHeaderDesktop>
        <SiteHeader>
          <SiteHeaderBranding>
            <Flex className="align-center">
              <Image
                onClick={() => navigate("/")}
                src={HeaderLogo}
                sx={{
                  height: "35px",
                  minWidth: "110px",
                  maxWidth: "110px",
                  cursor: "pointer",
                  marginBottom: "5px",
                }}
              />
            </Flex>
          </SiteHeaderBranding>
          <SiteHeaderNav>
            <SiteHeaderNavItem>
              <SiteHeaderNavButton onClick={() => toggleProductNav()}>
                Shop
              </SiteHeaderNavButton>
            </SiteHeaderNavItem>
            <SiteHeaderNavItem>
              <SiteHeaderNavLink onMouseOver={hideDrawers} to="/stories">
                Stories
              </SiteHeaderNavLink>
            </SiteHeaderNavItem>
            <SiteHeaderNavItem>
              <SiteHeaderNavLink onMouseOver={hideDrawers} to="/about">
                About
              </SiteHeaderNavLink>
            </SiteHeaderNavItem>
          </SiteHeaderNav>
          {!hideShippingBar && Boolean(freeShippingLimit) && (
            <SiteHeaderNote>
              <FreeShippingBar />
            </SiteHeaderNote>
          )}
          {!hideMenuItems && (
            <SiteHeaderExtras>
              <SiteHeaderExtrasItem onClick={toggleSearch}>
                Search
              </SiteHeaderExtrasItem>
              <SiteHeaderExtrasItem onClick={toggleRegionOpen}>
                {cart.countryCode?.toUpperCase()}—
                {cart.currencyCode?.toUpperCase()}
              </SiteHeaderExtrasItem>
              <SiteHeaderExtrasItem onClick={() => showCart()}>
                Cart ({handleItemsInCart(cart)})
              </SiteHeaderExtrasItem>
            </SiteHeaderExtras>
          )}
        </SiteHeader>
      </SiteHeaderDesktop>

      <SiteHeaderMobile>
        <SiteHeader>
          <SiteHeaderBranding>
            <Flex className="align-center" sx={{ width: "50%" }}>
              <Image
                onClick={() => navigate("/")}
                src={HeaderLogo}
                sx={{
                  height: "25px",
                  minWidth: "78px",
                  cursor: "pointer",
                  marginBottom: "5px",
                }}
              />
            </Flex>
            <SiteHeaderNote>
              {!hideShippingBar && <FreeShippingBar />}
            </SiteHeaderNote>
          </SiteHeaderBranding>
          <SiteHeaderExtras>
            <SiteHeaderExtrasItem
              onClick={() => {
                toggleProductNav()
                setNotificationList((list) => {
                  const result = [...list]
                  result.splice(0, 1)
                  return result
                })
              }}
            >
              <SiteHeaderMobileNavToggle />
            </SiteHeaderExtrasItem>
            {!hideMenuItems && (
              <SiteHeaderExtrasItem onClick={() => showCart()}>
                <span>{handleItemsInCart(cart)}</span>
              </SiteHeaderExtrasItem>
            )}
          </SiteHeaderExtras>
        </SiteHeader>
      </SiteHeaderMobile>
    </>
  )
}

export default Header
